import type { LoadInput, LoadOutput } from '@sveltejs/kit/types/internal';
import publicRoutes from './public-routes.json';
import userStore from 'src/stores/user';
import { browser } from '$app/env';

export async function routeGuard({ routeId }: LoadInput): Promise<LoadOutput> {
    const authed = await userStore.isAuthenticated();
    
    if (!browser) { // requires page auth
        return {};
    } else if (authed) {
        if (userStore.getSelectedProjectId())
            return { status: 200 };
        else if (routeId != 'teams')
            return { status: 302, redirect: '/teams'}
        return { status: 200 };
    
    } else if (publicRoutes.includes(routeId)) {
        return { status: 200 };
    } else {
        return { status: 302, redirect: '/' };
    }
}

export default routeGuard;